import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import InHouseForm from "../components/InHouseForm";

import Layout from "../components/layout";
import { inHouseForms } from "../config/inHouseForms";
import { getTitle } from "../helpers/globalHelpers";
import { checkUrlParamForNullUndefined } from "../utilities/utilities";

const CompareFormVersions = () => {
  const [formType, setFormType] = useState();
  const [formId, setFormId] = useState();
  const [submissionId, setSubmissionId] = useState();
  const [selectedTimestamp, setSelectedTimestamp] = useState();
  const [previousTimestamp, setPreviousTimestamp] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setFormType(urlParams.get("formType"));
    setFormId(urlParams.get("formId"));
    setSubmissionId(urlParams.get("submissionId"));
    setSelectedTimestamp(urlParams.get("selectedTimestamp"));
    setPreviousTimestamp(checkUrlParamForNullUndefined(urlParams.get("previousTimestamp")));
  });

  return (
    <Layout pageName="Edit Form">
      <p class="title">{getTitle(formType)}</p>
      <nav
        class="breadcrumb has-succeeds-separator"
        aria-label="breadcrumbs"
      >
        <ul>
          <li>
            <Link
              class="fpBreadcrumbLink"
              to={`/selectForm?formType=${formType}`}
            >
              Select a form
            </Link>
          </li>
          <li>
            <p class="fpMiddleBreadcrumb">Compare submissions</p>
          </li>
          <li>
            <p class="fpEndBreadcrumb">{`ID: ${submissionId}`}</p>
          </li>
        </ul>
      </nav>
      <div className="is-flex">
        {/* Previous version to compare, displays nothing if earliest version selected */}
        {previousTimestamp && (
          <div className="mx-4">
            <InHouseForm
              config={inHouseForms[formId]}
              submissionId={submissionId}
              timestamp={previousTimestamp}
            />
          </div>
        )}

        {/* Selected version */}
        <div className="mx-4">
          <InHouseForm
            config={inHouseForms[formId]}
            submissionId={submissionId}
            timestamp={selectedTimestamp}
            previousTimestamp={previousTimestamp}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CompareFormVersions;
